import React, { useEffect, useState } from "react";
import { OLD_Plugin as Plugin } from "@dash.bar/types";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { functions } from "../../../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "@material-ui/lab";
import { getCFPluginByID } from "./mapping";
import useAuth from "../../../../../hooks/useAuth";
import useProfile from "../../../../../hooks/firestore/useProfile";
import { UserRole } from "../../../../../firestore/models";
import { ICON_STYLE } from "../../../../../utils/constants";
import moment from "moment";
import { Refresh } from "@material-ui/icons";

type Props = {
  plugin: Plugin;
};

const TicketsView = ({ plugin }: Props) => {
  const auth = useAuth();
  const profile = useProfile();

  const getTickets = functions().httpsCallable("calls-freshdesk-getTickets");
  const [tickets, setTickets] = useState<Array<Record<string, any>> | null>(
    null
  );
  const [totalTickets, setTotalTickets] = useState<number>(0);
  const [openTickets, setOpenTickets] = useState<number>(0);
  const [closedTickets, setClosedTickets] = useState<number>(0);
  const [criticalTickets, setCriticalTickets] = useState<number>(0);
  const [topResolver, setTopResolver] = useState<Record<string, any>>({});

  const [from, setFrom] = React.useState<Date | null | undefined>(undefined);
  const [to, setTo] = React.useState<Date | null | undefined>(undefined);

  //Only updated after submit to prevent unnecessary requests
  const [fromSubmitted, setFromSubmitted] = React.useState<Date | null>(null);
  const [toSubmitted, setToSubmitted] = React.useState<Date | null>(null);

  const handleFromChange = (fromNew: React.ChangeEvent<HTMLInputElement>) => {
    try {
      new Date(fromNew.target.value).toISOString();
    } catch {
      return;
    }
    setFrom(new Date(fromNew.target.value));
  };
  const handleToChange = (toNew: React.ChangeEvent<HTMLInputElement>) => {
    try {
      new Date(toNew.target.value).toISOString();
    } catch {
      return;
    }
    setTo(new Date(toNew.target.value));
  };

  const submitDates = () => {
    setTickets(null);
    setFromSubmitted(from || null);
    setToSubmitted(to || null);
  };

  useEffect(() => {
    if (getCFPluginByID(plugin.pluginId) !== null) {
      // if ([`ws5_dashbar`, `ws5_doofinder`].includes(plugin.pluginId)) {
      getTickets({
        pluginId:
          // plugin.pluginId === `ws5_doofinder` ? "doofinder" : "dash.bar",
          getCFPluginByID(plugin.pluginId),
        from: fromSubmitted,
        to: toSubmitted,
      })
        .then((result) => {
          setTickets(result.data.tickets);
          setTotalTickets(+result.data.total);
          // console.log(JSON.stringify(result));
        })
        .catch(() => {
          setTickets([]);
        });
    }
  }, [fromSubmitted, toSubmitted]);

  useEffect(() => {
    if (tickets !== null) {
      setOpenTickets(
        tickets.filter((v) => {
          return [2, 3].includes(v.status);
        }).length
      );
      setClosedTickets(
        tickets.filter((v) => {
          return [4, 5].includes(v.status);
        }).length
      );
      setCriticalTickets(
        tickets.filter((v) => {
          return v.escalated_by_time === true;
        }).length
      );
      setTopResolver(
        tickets
          .map((v) => {
            return v.tags;
          })
          .flat()
          .reduce<Record<string, any>>((prev, curr) => {
            if (prev?.[curr]) {
              return { ...prev, [curr]: prev[curr] + 1 };
            } else {
              return { ...prev, [curr]: 1 };
            }
          }, {})
      );
    }
  }, [tickets]);
  //   console.log(topResolver);
  // if (![`ws5_dashbar`, `ws5_doofinder`].includes(plugin.pluginId)) {
  if (getCFPluginByID(plugin.pluginId) === null) {
    return (
      <div>
        <p>{`Not supported for ${plugin.pluginId} yet`}</p>
      </div>
    );
  }
  if (auth && !profile.roles.includes(UserRole.Developer)) {
    return null;
  }
  return (
    <div>
      <div
        className="bg-blue-700"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
          borderRadius: 15,
          fontSize: 18,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Von
          <input
            type={`date`}
            value={moment(from || new Date()).format("YYYY-MM-DD")}
            onChange={handleFromChange}
            style={{
              padding: 3,
              borderRadius: 10,
              marginLeft: 5,
              marginRight: 10,
            }}
          />
          Bis
          <input
            type={`date`}
            value={moment(to || new Date()).format("YYYY-MM-DD")}
            onChange={handleToChange}
            style={{
              padding: 3,
              borderRadius: 10,
              marginLeft: 5,
              marginRight: 10,
            }}
          />
          <IconButton onClick={submitDates}>
            <Refresh fontSize={"small"} />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
          height: 130,
          flexShrink: "revert",
          flexWrap: "wrap",
          marginTop: 20,
        }}
      >
        {tickets === null ? (
          <Skeleton
            width={"30%"}
            height={"100%"}
            variant="rect"
            style={{ borderRadius: 15, margin: 10 }}
          />
        ) : (
          <div
            style={{
              margin: 10,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              color: "black",
              width: "30%",
              flexShrink: "revert",
              padding: 10,
              borderRadius: 15,
            }}
          >
            <Grid
              container
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: "light",
                    marginBottom: 15,
                  }}
                >
                  {`Tickets (insgesamt)`}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    marginBottom: 5,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {`${totalTickets}`}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: "light",
                    marginBottom: 15,
                  }}
                >
                  {`Auslastung (aktuell)`}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    color:
                      openTickets < 5
                        ? `#25732b`
                        : openTickets < 7
                        ? `#000000`
                        : openTickets < 15
                        ? `#c26c10`
                        : `#69130f`,
                    marginBottom: 5,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {openTickets < 5
                    ? `niedrig`
                    : openTickets < 7
                    ? `normal`
                    : openTickets < 15
                    ? `hoch`
                    : `kritisch`}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
        {tickets === null ? (
          <Skeleton
            width={"30%"}
            height={"100%"}
            variant="rect"
            style={{ borderRadius: 15, margin: 10 }}
          />
        ) : (
          <div
            style={{
              margin: 10,
              display: "flex",
              height: "100%",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              color: "black",
              width: "30%",
              flexShrink: "revert",
              padding: 10,
              borderRadius: 15,
            }}
          >
            <Grid
              container
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={12}>
                <Typography
                  style={{ fontSize: 12, fontWeight: "light", marginBottom: 5 }}
                >
                  {`Status (aktuell)`}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ fontSize: 14, fontWeight: "light", marginBottom: 2 }}
                >
                  {`Offen`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: 2,
                  }}
                >
                  {`${openTickets}/${tickets.length}`}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {openTickets < 5 ? (
                  <FontAwesomeIcon
                    color="green"
                    icon={[ICON_STYLE, "check-circle"]}
                  />
                ) : openTickets < 10 ? (
                  <FontAwesomeIcon
                    color="orange"
                    icon={[ICON_STYLE, "exclamation-circle"]}
                  />
                ) : (
                  <FontAwesomeIcon
                    color="red"
                    icon={[ICON_STYLE, "exclamation-circle"]}
                  />
                )}
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ fontSize: 14, fontWeight: "light", marginBottom: 2 }}
                >
                  {`Gelöst`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ fontSize: 16, fontWeight: "bold", marginBottom: 2 }}
                >
                  {`${closedTickets}/${tickets.length}`}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {closedTickets > 25 || tickets.length <= 5 ? (
                  <FontAwesomeIcon
                    color="green"
                    icon={[ICON_STYLE, "check-circle"]}
                  />
                ) : closedTickets > 20 || tickets.length <= 10 ? (
                  <FontAwesomeIcon
                    color="orange"
                    icon={[ICON_STYLE, "exclamation-circle"]}
                  />
                ) : (
                  <FontAwesomeIcon
                    color="red"
                    icon={[ICON_STYLE, "exclamation-circle"]}
                  />
                )}
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{ fontSize: 14, fontWeight: "light", marginBottom: 2 }}
                >
                  {`Problematisch`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{ fontSize: 16, fontWeight: "bold", marginBottom: 2 }}
                >
                  {`${criticalTickets}/${tickets.length}`}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {criticalTickets < 1 ? (
                  <FontAwesomeIcon
                    color="green"
                    icon={[ICON_STYLE, "check-circle"]}
                  />
                ) : criticalTickets < 5 ? (
                  <FontAwesomeIcon
                    color="orange"
                    icon={[ICON_STYLE, "exclamation-circle"]}
                  />
                ) : (
                  <FontAwesomeIcon
                    color="red"
                    icon={[ICON_STYLE, "exclamation-circle"]}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )}
        {tickets === null ? (
          <Skeleton
            width={"30%"}
            height={"100%"}
            variant="rect"
            style={{ borderRadius: 15, margin: 10 }}
          />
        ) : (
          <div
            style={{
              margin: 10,
              display: "flex",
              height: "100%",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              color: "black",
              width: "30%",
              flexShrink: "revert",
              padding: 10,
              borderRadius: 15,
            }}
          >
            <Grid
              container
              style={{ alignContent: "center", alignItems: "center" }}
            >
              <Grid item xs={12}>
                <Typography
                  style={{ fontSize: 12, fontWeight: "light", marginBottom: 5 }}
                >
                  {`Top-Bearbeiter (aktuell)`}
                </Typography>
              </Grid>
              {Object.entries(topResolver || {})
                .sort(([, v1], [, v2]) => {
                  if (v1 > v2) {
                    return -1;
                  } else if (v1 > v2) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .filter(([], index) => {
                  return index < 4;
                })
                .map(([k, v], index) => {
                  return (
                    <>
                      <Grid item xs={1}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            marginBottom: 2,
                          }}
                        >
                          {`${index + 1}.`}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            marginBottom: 2,
                          }}
                        >
                          {`${k}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          style={{
                            fontSize: 12,
                            fontWeight: "normal",
                            marginBottom: 2,
                          }}
                        >
                          {`${v}`}
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketsView;
