import {
    Button, Select, MenuItem,
    TableCell,
    TableRow,
    TableBody, TextField, InputBaseComponentProps, IconButton
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import firebase from "firebase/compat/app";

type ServiceObject = {
    cServiceId: string,
    cServiceIdString: string,
    jTranslations: string,
    jAttributes: string,
    kCategory: number,
    keywords?: string[],
    cJtlConsentIds?: string[],
}

type DetailPageProps = {
    selectedItem: ServiceObject;
};

const DetailPage = ({ selectedItem }: DetailPageProps) => {
    const translations = JSON.parse(selectedItem.jTranslations);
    const attributes = JSON.parse(selectedItem.jAttributes);
    const firestore = useFirestore()
    const db = firestore.collection("plugin").doc("ws5_eucookie").collection("services");

    const [name, setName] = useState<string>(translations.name.de);
    const [description, setDescription] = useState<string>(translations.description.de);
    const [cookieInfo, setCookieInfo] = useState<string>(translations.cookieInfo ? translations.cookieInfo.de : "");
    const [serviceId, setServiceId] = useState<string>(selectedItem.cServiceId);

    const [serviceIdString, setServiceIdString] = useState<string>(selectedItem.cServiceIdString);

    const [keywords, setKeywords] = useState<Array<string>>(selectedItem.keywords || []);
    const [category, setCategory] = useState<number>(selectedItem.kCategory);
    const [jtlConsentIds, setJtlConsentIds] = useState<Array<string>>(selectedItem.cJtlConsentIds || []);

    const categoryChangeHandler = (event: any) => {
        const value = event.target.value;
        setCategory(value);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === "name") {
            setName(value);
        } else if (name === "description") {
            setDescription(value);
        } else if (name === "cookieInfo") {
            setCookieInfo(value);
        } else if (name === "keywords") {
            const keywordsArray = value.split(",").map((keyword) => keyword.trim()); // Split the input value into an array and trim each keyword
            if (value.trim() === "") {
                setKeywords([]);
            } else {
                setKeywords(keywordsArray);
            }
        } else if (name === "jtlConsentIds") {
            const jtlConsentIdsArray = value.split(",").map((jtlConsentId) => jtlConsentId.trim()); // Split the input value into an array and trim each Jtl-Consent-Id
            if (value.trim() === "") {
                setJtlConsentIds([]);
            } else {
                setJtlConsentIds(jtlConsentIdsArray);
            }
        }
    };

    const handleSave = async () => {
        try {
            const updatedTranslations = {
                name: name ? { de: name } : undefined,
                description: description ? { de: description } : undefined,
                cookieInfo: cookieInfo ? { de: cookieInfo } : undefined,
            };

            const updatedAttributes = { ...attributes };

            const updatedService = {
                jTranslations: JSON.stringify(updatedTranslations),
                jAttributes: JSON.stringify(updatedAttributes),
                kCategory: category,
                keywords: keywords.length > 0 ? keywords : firebase.firestore.FieldValue.delete(),
                cJtlConsentIds: jtlConsentIds.length > 0 ? jtlConsentIds : firebase.firestore.FieldValue.delete(),
            };

            await db.doc(selectedItem.cServiceId).update(updatedService);

            console.log("Service updated successfully!");
        } catch (error) {
            console.error("Error updating service:", error);
        }
    };

    const handleDelete = async () => {
        try {
            const sourcesCollectionRef = db
                .doc(selectedItem.cServiceId)
                .collection("sources");

            // Check if sources exist
            const sourcesSnapshot = await sourcesCollectionRef.get();
            if (!sourcesSnapshot.empty) {
                // Delete all sources
                const deleteSourcePromises: Promise<void>[] = [];
                sourcesSnapshot.forEach((doc) => {
                    deleteSourcePromises.push(doc.ref.delete());
                });
                await Promise.all(deleteSourcePromises);
            }

            // Delete the service
            await db.doc(selectedItem.cServiceId).delete();


            setCategory(0);
            setKeywords([]);
            setJtlConsentIds([]);
            setDescription("");
            setCookieInfo("");
            setName("");
            setServiceIdString("");
            setServiceId("");
            console.log("Service and sources deleted successfully!");
        } catch (error) {
            console.error("Error deleting service and sources:", error);
        }
    };

    const inputProps: InputBaseComponentProps = {
        style: { width: `${name.length + 1}ch` } // Adjust width based on value length
    };

    return (
        <TableBody>
            <TableRow>
                <TableCell>{serviceIdString}</TableCell>
                <TableCell>{serviceId}</TableCell>
                <TableCell>
                    <TextField
                        name="name"
                        value={name}
                        onChange={handleChange}
                        inputProps={{
                            ...inputProps,
                            style: { ...inputProps.style, width: `${name.length + 1}ch` }
                        }}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        name="description"
                        value={description}
                        onChange={handleChange}
                        inputProps={{
                            ...inputProps,
                            style: { ...inputProps.style, width: `${description.length + 1}ch` }
                        }}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        name="cookieInfo"
                        value={cookieInfo}
                        onChange={handleChange}
                        inputProps={{
                            ...inputProps,
                            style: { ...inputProps.style, width: `${cookieInfo.length + 1}ch` }
                        }}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        name="keywords"
                        value={Array.isArray(keywords) ? keywords.join(", ") : ""}
                        onChange={handleChange}
                    />
                </TableCell>

                <TableCell>
                    <Select
                        value={category}
                        label="Kategorie"
                        onChange={categoryChangeHandler}
                    >
                        <MenuItem value={1}>Kein Cookie</MenuItem>
                        <MenuItem value={2}>Notwendig</MenuItem>
                        <MenuItem value={3}>Marketing</MenuItem>
                        <MenuItem value={4}>Analytics</MenuItem>
                        <MenuItem value={999}>Zahlungsanbieter</MenuItem>
                    </Select>
                </TableCell>

                <TableCell>
                    <TextField
                        name="jtlConsentIds"
                        value={Array.isArray(jtlConsentIds) ? jtlConsentIds.join(", ") : ""}
                        onChange={handleChange}
                    />
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell colSpan={8} align="right">
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                    <IconButton color="secondary" onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>

        </TableBody>

    );
};

export default DetailPage;