import { useFirestoreConnect } from "react-redux-firebase";
import {
  COLL_PLUGIN,
  COLL_PLUGIN_RELEASE,
  COLL_PLUGIN_SHOPDESCR,
  DB,
} from "../../firebase";
import { useSelector } from "react-redux";
import { PluginDescription, Release } from "@dash.bar/types";
// import useProfile from "./useProfile";
// import useAuth from "../useAuth";
// import { OLD_Plugin } from "@dash.bar/types";
// import { UserRole } from "../../firestore/models";

// /**
//  * @deprecated
//  * @param where
//  */
// const usePlugins = (
//   where?: WhereOptions | WhereOptions[] | undefined
// ): OLD_Plugin[] => {
//   const auth = useAuth();
//   const profile = useProfile();
//   if (auth && !profile.roles.includes(UserRole.Admin)) {
//     if (!where) {
//       where = ["access", "array-contains", auth.uid] as WhereOptions;
//     } else {
//       where.push(["access", "array-contains", auth.uid]);
//     }
//   }

//   useFirestoreConnect(() => [
//     {
//       collection: COLL_PLUGIN,
//       orderBy: ["pluginId", "asc"],
//       where: where,
//     },
//   ]);
//   const plugins: OLD_Plugin[] = useSelector(
//     (state: { firestore: { ordered: { plugin?: OLD_Plugin[] } } }) =>
//       state.firestore.ordered.plugin || []
//   );
//   return plugins;
// };

/**
 * @deprecated
 * @param id
 */
export const usePlugin = <T>(id: string) => {
  useFirestoreConnect(`${COLL_PLUGIN}/${id}`);

  const plugin = useSelector(
    (state: { firestore: { data: { plugin?: Record<string, T> } } }) =>
      state.firestore.data.plugin
        ? state.firestore.data.plugin[id]
        : null ?? null
  );

  return plugin;
};

/**
 * @deprecated
 * @param id
 */
export const usePluginShopdescr = <T>(id: string) => {
  const storeAs = `plugin:${id}:shortdescr`;
  useFirestoreConnect({
    collection: COLL_PLUGIN_SHOPDESCR(id),
    storeAs: storeAs,
    orderBy: ["sort", "asc"],
  });

  return (
    useSelector(
      (state: { firestore: { ordered: { [key: string]: T[] } } }) =>
        state.firestore.ordered[storeAs]
    ) || ([] as T[])
  );
};

/**
 * @deprecated
 * @param id
 */
export const usePluginReleases = <T>(id: string) => {
  const storeAs = `plugin:${id}:releases`;
  useFirestoreConnect({
    collection: COLL_PLUGIN_RELEASE(id),
    storeAs: storeAs,
    where: ["version", ">", ""],
    orderBy: ["version", "desc"],
  });

  return (
    useSelector(
      (state: {
        firestore: { ordered: { [key: string]: Record<string, T> } };
      }) => state.firestore.ordered[storeAs]
    ) || ({} as Record<string, T>)
  );
};

export const batchPluginShopdescr = (
  pluginId: string,
  data: PluginDescription[]
) => {
  const batch = DB().batch();
  console.log(JSON.stringify(data, null, 2), data, data.length);
  data.forEach(async (value) => {
    const ref = DB().collection(COLL_PLUGIN_SHOPDESCR(pluginId)).doc();
    batch.set(ref, value, { merge: true });
    console.log(ref.id, value);
  });
  return batch.commit();
};

/**
 * @deprecated
 * @param pluginId
 * @param descrId
 * @param data
 */
export const savePluginShopdescr = (
  pluginId: string,
  descrId: string | undefined,
  data: PluginDescription
) =>
  DB()
    .collection(COLL_PLUGIN_SHOPDESCR(pluginId))
    .doc(descrId)
    .set(data, { merge: true });

/**
 * @deprecated
 * @param pluginId
 * @param descrId
 */
export const deletePluginShopdescr = (pluginId: string, descrId: string) =>
  DB().collection(COLL_PLUGIN_SHOPDESCR(pluginId)).doc(descrId).delete();

/**
 * @deprecated
 * @param pluginId
 */
export const deletePlugin = (pluginId: string) =>
  DB().collection(COLL_PLUGIN).doc(pluginId).delete();

/**
 * @deprecated
 * @param pluginId
 * @param version
 * @param release
 */
export const saveRelease = (
  pluginId: string,
  version: string,
  release: Release
) =>
  DB()
    .collection(COLL_PLUGIN_RELEASE(pluginId))
    .doc(version)
    .set(release, { merge: true });

// export default usePlugins;
